import type { ReactNode } from "react"
import { useState } from "react"
import { trpcClient } from "@/trpc/client"
import { ChatClient, ChatClientProvider } from "@ably/chat"
import { useLocation } from "@remix-run/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink } from "@trpc/react-query"
import * as Ably from "ably"
import { AblyProvider } from "ably/react"
import superjson from "superjson"

import { DetailsMultiStateProvider } from "../shared/details/multi-select-provider"
import { ShopWatchGlobalProvider } from "./shop-watch/global-context"

interface ProvidersProps {
	children?: ReactNode
}

export const Providers = ({ children }: ProvidersProps) => {
	const [queryClient] = useState(() => new QueryClient())
	const [trpcClientState] = useState(() => {
		return trpcClient.createClient({
			links: [
				httpBatchLink({
					url: "/api/trpc",
					transformer: superjson,
				}),
			],
		})
	})
	const location = useLocation()

	return (
		<trpcClient.Provider client={trpcClientState} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<AblyProviders>
					<ShopWatchGlobalProvider>
						<DetailsMultiStateProvider key={location.pathname}>
							{children}
						</DetailsMultiStateProvider>
					</ShopWatchGlobalProvider>
				</AblyProviders>
			</QueryClientProvider>
		</trpcClient.Provider>
	)
}

{
	/* 
	We only render Ably providers on the client side because:
	1. The Ably realtime client creates a stateful connection for realtime updates
		which must be initialized in the browser
	2. When running on the server, the authUrl starting with "/" causes errors
		since Node.js can't resolve relative URLs
	3. Server-side rendering isn't necessary for realtime functionality
		since it only works in the browser anyway
*/
}
const AblyProviders = ({ children }: { children: ReactNode }) => {
	return typeof window === "undefined" ? children : (
			<AblyClientProviders>{children}</AblyClientProviders>
		)
}

const AblyClientProviders = ({ children }: { children: ReactNode }) => {
	const [ablyClient] = useState(
		() => new Ably.Realtime({ authUrl: "/api/ably-auth" }),
	)
	const [ablyChat] = useState(() => new ChatClient(ablyClient))

	return (
		<AblyProvider client={ablyClient}>
			<ChatClientProvider client={ablyChat}>
				{children}
			</ChatClientProvider>
		</AblyProvider>
	)
}
